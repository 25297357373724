<template>
  <div class="page">
    <Head />
    <Sidebar />
    <div class="body-main">
      <breadInfo />
      <div class="container bg flex-1 flex-v">
        
        <!-- <div class="pub-tt m15">
          <div class="tabs">
            <router-link to="/set-jiliangbiao" class="lk">计量表</router-link>
            <router-link to="/set-sensor" class="lk">传感器</router-link>
            <router-link to="/set-virtual" class="lk">虚拟表</router-link>
            <router-link to="/set-outline" class="lk">离线表</router-link>
            <router-link to="/set-outlineFile" class="lk"
              >离线表文件</router-link
            >
            <router-link to="" class="lk cur">绑定数据点</router-link>
          </div>
          <div class="dix"></div>
        </div> -->
        <div class="gauges-sec flex-1 scrollybg">
          <div class="choose">
            <el-select
              v-model="equimentId"
              placeholder="选择设备"
              @change="select"
            >
              <el-option
                v-for="item in equiments"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </div>
          <div class="flex flex-pack-justify">
            <div class="col">
              <div class="mod mod1">
                <h5 class="smtit">计量表</h5>
                <div class="detail">
                  <el-form ref="form" :model="biaoForm">
                    <el-form-item label="请选择表计类型：">
                      <el-select
                        v-model="biaoForm.type"
                        placeholder="表计类型"
                        @change="select"
                      >
                        <el-option label="计量表" value="1"></el-option>
                        <el-option label="传感器" value="2"></el-option>
                        <el-option label="虚拟表" value="3"></el-option>
                        <el-option label="离线表" value="4"></el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item label="请选择表计：">
                      <el-select
                        v-model="biaoForm.meter"
                        placeholder="请选择表计"
                        @change="getBindPoints"
                      >
                        <el-option
                          v-for="item in meters"
                          :key="item.value"
                          :label="item.name"
                          :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mod mod2">
                <h5 class="smtit">绑定的数据点</h5>
                <div class="detail bind">
                  <draggable
                    v-model="pointList"
                    :group="groupBind"
                    animation="300"
                    @add="onAdd"
                    @remove="onRemove"
                    class="drag-content"
                  >
                    <div
                      v-for="(point, index) in pointList"
                      class="list"
                      :key="index"
                    >
                      <router-link to="" class="lk bgfull">{{
                        point.pointName
                      }}</router-link>
                    </div>
                  </draggable>
                </div>
              </div>
              <div class="mod mod3">
                <h5 class="smtit">回收站</h5>
                <div class="detail">
                  <div class="delete">
                    <draggable
                      :group="groupDelete"
                      animation="300"
                      :sort="true"
                      style="height: 100%; width: 100%"
                    ></draggable>
                    <!-- <img src="../assets/images/set_10.png" alt="" class="icon" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="mod mod4">
                <h5 class="smtit">数据点</h5>
                <div class="detail all">
                  <div class="mb20">
                    <el-input
                      placeholder="请输入内容"
                      v-model="searchInput"
                      class=""
                      @input="handleInput()"
                    >
                      <el-button
                        slot="append"
                        icon="el-icon-search"
                      ></el-button>
                    </el-input>
                  </div>
                  <draggable
                    v-model="fillist"
                    :group="groupAll"
                    animation="300"
                  >
                    <div
                      v-for="(point, key) in fillist"
                      class="list"
                      :key="key"
                    >
                      <router-link to="" class="lk bgfull">{{
                        point.pointName
                      }}</router-link>
                    </div>
                  </draggable>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/head.vue";
import Sidebar from "@/components/sidebar.vue";
import breadInfo from "@/components/breadInfo.vue";
import draggable from "vuedraggable";
import {
  getEquipList,
  getMeterList,
  getVirtualMeterList,
  getOutlineMeterList,
  getSensorList,
} from "@/utils/common";
import {
  getBindPointList,
  getNotBindPointList,
  addBindPoint,
  delBindPoint,
} from "@/api/meter";
export default {
  data() {
    return {
      equimentId: "",
      activeName: "jiliangbiao",
      equiments: [],
      meters: [],
      biaoForm: {
        type: "",
        equip: "",
      },
      searchInput: "",

      pointList: [],
      bindPointList: [],
      allPointList: [],
      fillist: [],
      groupBind: {
        name: "itxst",
        pull: true, // 拖出
        put: true, // 拖入
      },
      groupAll: {
        name: "itxst",
        pull: true, // 拖出
        put: false, // 拖入
      },
      groupDelete: {
        name: "itxst",
        pull: false, // 拖出
        put: true, // 拖入
      },
    };
  },
  components: {
    Head,
    Sidebar,
    draggable,
    breadInfo,
  },
  created() {
    this.equiments = getEquipList();
  },
  mounted() {},
  watch: {},
  methods: {
    //取两个数组的差值
    getArrDifference(arr1, arr2) {
      return arr1.concat(arr2).filter(function (v, i, arr) {
        return arr.indexOf(v) === arr.lastIndexOf(v);
      });
    },
    //获取表计列表
    select() {
      if (this.biaoForm.type == "") {
        return;
      }
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        this.biaoForm.type = "";
        return;
      } else {
        if (this.biaoForm.type == "1") {
          this.meters = getMeterList(this.equimentId);
        } else if (this.biaoForm.type == "2") {
          this.meters = getSensorList(this.equimentId);
        } else if (this.biaoForm.type == "3") {
          this.meters = getVirtualMeterList(this.equimentId);
        } else {
          this.meters = getOutlineMeterList(this.equimentId);
        }
      }
    },
    //搜索
    handleInput() {
      this.fillist = [];
      for (let i = 0; i < this.allPointList.length; i++) {
        if (this.allPointList[i].name.includes(this.searchInput)) {
          this.fillist.push(this.allPointList[i]);
        }
      }
    },
    //绑定数据点
    onAdd(e) {
      let t = this.getArrDifference(this.pointList, this.bindPointList);
      let param = {
        gaugesId: this.biaoForm.meter,
        pointId: this.getArrDifference(this.pointList, this.bindPointList)[0]
          .id,
        pointName: this.getArrDifference(this.pointList, this.bindPointList)[0]
          .name,
        type: this.biaoForm.type,
      };
      addBindPoint(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindPoints();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //解绑数据点
    onRemove(e) {
      let t = this.getArrDifference(this.pointList, this.bindPointList);
      let param = {
        ids: this.getArrDifference(this.pointList, this.bindPointList)[0].id,
      };
      delBindPoint(param).then((res) => {
        if (res.data.code == 0) {
          this.getBindPoints();
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
    //获取已绑定的数据点列表
    getBindPoints() {
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        deviceId: this.equimentId,
        gaugesId: this.biaoForm.meter,
        type: this.biaoForm.type,
      };
      getBindPointList(param).then((res) => {
        if (res.data.code == 0) {
          this.pointList = res.data.rows;
          this.bindPointList = this.pointList;
        } else {
          this.$message.warning(res.data.msg);
        }
      });
      this.getNotBindPoints();
    },
    //获取指定类型的未绑定数据点列表
    getNotBindPoints() {
      if (this.equimentId == "") {
        this.$message.warning("请选择设备");
        return;
      }
      let param = {
        pageNum: 1,
        pageSize: 999999999,
        gaugesId: this.biaoForm.meter,
        type: this.biaoForm.type,
      };
      getNotBindPointList(param).then((res) => {
        if (res.data.code == 0) {
          for (let i = 0; i < res.data.rows.length; i++) {
            res.data.rows[i].pointName = res.data.rows[i].name;
          }
          this.allPointList = res.data.rows;
          this.fillist = this.allPointList;
          this.searchInput = "";
        } else {
          this.$message.warning(res.data.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
.gauges-sec {
  padding: 0 60px;
}

.choose {
  width: 260px;
  margin-bottom: 25px;
}

.col {
  width: 30%;
  max-width: 387px;
}

.mod {
  margin-bottom: 25px;
}

.mod .smtit {
  font-size: 18px;
  text-align: center;
  width: 340px;
  line-height: 44px;
  margin: 0 auto;
  height: 44px;
  overflow: hidden;
  padding-top: 2px;
}

.mod .detail {
  padding: 25px 35px;
}

.mod .detail.bind {
  height: 300px;
  overflow-y: auto;
}

.drag-content {
  height: 240px;
}

.mod .detail.all {
  height: 600px;
  overflow-y: auto;
}

.mod1 {
  min-height: 300px;
  background: url(../assets/images/set_01.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 .smtit {
  background: url(../assets/images/set_05.png) no-repeat center;
  background-size: 100% 100%;
}

.mod1 >>> .el-select {
  display: block;
}

.mod1 >>> .el-form-item__label {
  float: none;
  text-align: left;
}

.mod2 {
  min-height: 348px;
  background: url(../assets/images/set_02.png) no-repeat center;
  background-size: 100% 100%;
}

.mod2 .smtit {
  background: url(../assets/images/set_06.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 {
  min-height: 378px;
  background: url(../assets/images/set_03.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .smtit {
  background: url(../assets/images/set_07.png) no-repeat center;
  background-size: 100% 100%;
}

.mod3 .delete {
  background: url(../assets/images/set_10.png) no-repeat center;
  background-size: 100% 100%;
  width: 330px;
  height: 290px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mod3 .delete .icon {
  width: 180px;
}

.mod4 {
  min-height: 658px;
  background: url(../assets/images/set_04.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 .smtit {
  background: url(../assets/images/set_08.png) no-repeat center;
  background-size: 100% 100%;
}

.mod4 >>> .el-input-group {
  background: rgba(26, 29, 50, 0.5);
  border: 1px solid #4a4f64;
  border-radius: 4px;
  overflow: hidden;
}

.mod4 >>> .el-input-group__append {
  background: transparent;
  border: none;
  color: #fff;
}

.mod4 >>> .el-input-group .el-input__inner {
  border: none !important;
  background: none !important;
}

.list .lk {
  display: flex;
  font-size: 16px;
  height: 52px;
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}

.list .lk:last-child {
  margin-bottom: 0;
}

.mod2 .list .lk {
  background-image: url(../assets/images/set_13.png);
}

.mod2 .list .lk:hover {
  color: #8e7ff6;
}

.mod4 .list .lk {
  background-image: url(../assets/images/set_12.png);
}

.mod4 .list .lk:hover {
  color: #71aef5;
}
</style>
